<template>
  <v-snackbar app color="primary" :value="$t(snackbar)">
    {{ $t(snackbar) }}
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    }
  },
  watch: {
    snackbar(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$store.dispatch("setSnackbar", null);
        }, 5000);
      }
    }
  }
};
</script>
